import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from "jquery";

export default class extends Controller {
  static targets = [ "orgSelect", "newOrgFields", 'nominationRadio' ]

  connect() {
    StimulusReflex.register(this)
  }

  doNewOrgFieldsToggle(event) {
    if (event.target.checked) {
      if (this.hasOrgSelectTarget) {
        this.orgSelectTarget.classList.add('d-none')

        $(this.orgSelectTarget).find('select').val('').trigger('change')
        $('#registrations-med-org-details').empty()
      }
      if (this.hasNewOrgFieldsTarget) {
        this.newOrgFieldsTarget.classList.remove('d-none')
      }
    } else {
      if (this.hasOrgSelectTarget) {
        this.orgSelectTarget.classList.remove('d-none')
      }
      if (this.hasNewOrgFieldsTarget) {
        this.newOrgFieldsTarget.classList.add('d-none')
      }
    }
  }

  loadOrganization(event) {
    let data = event.detail.event.params.data
    if (!data.id) { return }

    this.stimulate('Registrations::Organization#details', { serializeForm: false }, data.id)
  }

  loadVocNominations(event) {
    let id = $(event.target).val()
    if (!id) { return }

    this.stimulate('Registrations::VocNominationType#nominations_details', { serializeForm: false }, id)
  }
}
